<template>
	<horizontal-nav
	v-if="user"
	class="p-t-10 p-b-10"
	:items="items"
	:set_view="true"
	:show_display="false"></horizontal-nav>
</template> 
<script>
import HorizontalNav from '@/common-vue/components/horizontal-nav/Index'

import orders from '@/mixins/orders'
export default {
	mixins: [orders],
	components: {
		HorizontalNav,
	},
	computed: {
		items() {
			let items = []
			if (this.can('order.store')) {
				items.push({name: 'nuevo pedido', commit: ['order/setModel', 'order/setProducts', 'order/setTotal', 'order/setIndex']})
			}
			if (this.can('order.index')) {
				if (this.unconfirmed_orders.length) {
					items.push({name: 'lista', alert: this.unconfirmed_orders.length, action: 'order/getModels'})
				} else {
					items.push({name: 'lista', action: 'order/getModels'})
				}
			}
			// if (this.is_owner) {
			// 	items.push({name: 'eliminados', alert: this.deleted_orders.length, action: 'order/getDeletedModels'})
			// }
			return items
		},
		display_nav() {
			return this.view == 'lista'
		}
	},
	methods: {
		setDisplay(display) {
			this.$store.commit('orders/setDisplay', display)
		}
	}
}
</script>